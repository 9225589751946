export class PricingSettings {
  id!: number;
  companyId!: number;
  type?: PricingType;
  price?: number;
  degressivePrice?: PriceQuantity[];
  periodicity?: PricingPeriodicity;
  serviceOfferId?: number;
  quantity?: number;
}

export class PriceQuantity {
  quantity!: number;
  price!: number;
}

export enum PricingPeriodicity {
  ANNUALLY = 'annually',
  MONTHLY = 'monthly',
}

export enum PricingType {
  DEGRESSIVE = 'degressive',
  FIXED = 'fixed',
}
