export class Service {
  id!: number;
  state?: ServiceStateEnum;
  trialStartedAt?: Date;
  trialPeriodDays?: number;
  activatedAt?: string;
  suspendedAt?: string;
  isUnpaidSince?: string;
  companyId!: number;
  parentServiceId?: number;
}

export enum ServiceStateEnum {
  NOT_STARTED = 'not_started',
  PREQUALIFIED = 'prequalified',
  WAITING = 'waiting',
  ONBOARDING = 'onboarding',
  PENDING = 'pending',
  ACTIVATED = 'activated',
  SUSPENDED = 'suspended',
  OFFBOARDING = 'offboarding',
  TERMINATED = 'terminated',
  ARCHIVED = 'archived',
  UNKNOWN = 'unknown',
}
