import { ServiceOffer, ServiceType } from '../dto';

const getDefaults = (): ServiceOffer => ({
  id: 1,
  serviceType: ServiceType.ACCOUNTING,
  serviceCode: undefined,
  offerId: 1,
  serviceId: 1,
  pricingSettings: {
    id: 1,
    companyId: 1,
    type: undefined,
    price: undefined,
    degressivePrice: undefined,
    periodicity: undefined,
    serviceOfferId: undefined,
    quantity: undefined,
  },
  invoicingSettings: {
    id: 1,
    companyId: 1,
    periodicity: undefined,
    adjustmentInstallmentPaymentCount: undefined,
    adjustmentDiscountPercent: undefined,
    isFreeForever: undefined,
    hasAdjustment: undefined,
  },
  service: {
    id: 1,
    state: undefined,
    trialStartedAt: undefined,
    trialPeriodDays: undefined,
    activatedAt: undefined,
    suspendedAt: undefined,
    isUnpaidSince: undefined,
    companyId: 1,
    parentServiceId: undefined,
  },
});

export const getServiceOfferMock = (p?: Partial<ServiceOffer>): ServiceOffer => ({
  ...getDefaults(),
  ...p,
});
