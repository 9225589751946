import { ServicesState } from '../dto';
import { SERVICE_ELIGIBILITY, SERVICE_STATE, SERVICE_TYPE } from '../enums';

const getDefaults = (): ServicesState => ({
  accounting: {
    id: 1,
    type: SERVICE_TYPE.ACCOUNTING,
    typeLabel: 'type',
    state: SERVICE_STATE.ACTIVATED,
    eligibility: SERVICE_ELIGIBILITY.ELIGIBLE,
    isTrialing: undefined,
    trialRemainingDays: undefined,
    trialPeriodDays: undefined,
    isFree: undefined,
    isValid: undefined,
    isActive: undefined,
    serviceCode: undefined,
    serviceCodeLabel: undefined,
    activatedAt: undefined,
    suspendedAt: undefined,
    shouldShowModuleLink: true,
  },
  invoicing: {
    id: 1,
    type: SERVICE_TYPE.INVOICING,
    typeLabel: 'type',
    state: SERVICE_STATE.ACTIVATED,
    eligibility: SERVICE_ELIGIBILITY.ELIGIBLE,
    isTrialing: undefined,
    trialRemainingDays: undefined,
    trialPeriodDays: undefined,
    isFree: undefined,
    isValid: undefined,
    isActive: undefined,
    serviceCode: undefined,
    serviceCodeLabel: undefined,
    activatedAt: undefined,
    suspendedAt: undefined,
    shouldShowModuleLink: true,
  },
  creation: {
    id: 1,
    type: SERVICE_TYPE.LEGAL,
    typeLabel: 'type',
    state: SERVICE_STATE.ACTIVATED,
    eligibility: SERVICE_ELIGIBILITY.ELIGIBLE,
    isTrialing: undefined,
    trialRemainingDays: undefined,
    trialPeriodDays: undefined,
    isFree: undefined,
    isValid: undefined,
    isActive: undefined,
    serviceCode: undefined,
    serviceCodeLabel: undefined,
    activatedAt: undefined,
    suspendedAt: undefined,
    shouldShowModuleLink: true,
  },
  features: {
    hasIntercom: true,
    hasInvoicingHelp: true,
    hasNotification: true,
    hasNotificationEmailConfirmation: true,
    hasSettingsHelp: true,
  },
});

export const getServicesStateMock = (p?: Partial<ServicesState>): ServicesState => ({
  ...getDefaults(),
  ...p,
});
