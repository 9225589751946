export enum SERVICE_STATE {
  NOT_STARTED = 'not_started',
  PREQUALIFIED = 'prequalified',
  WAITING = 'waiting',
  ONBOARDING = 'onboarding',
  PENDING = 'pending',
  ACTIVATED = 'activated',
  SUSPENDED = 'suspended',
  OFFBOARDING = 'offboarding',
  TERMINATED = 'terminated',
  ARCHIVED = 'archived',
  UNKNOWN = 'unknown',
}
