import { ServiceOffer } from './service-offer.dto';

export class Offer {
  id!: number;
  state!: OfferState;
  companyId!: number;
  paymentMethodId!: number;
  serviceOffers: ServiceOffer[] = [];
}

export enum OfferState {
  PENDING = 'pending',
  COMPLETED = 'completed',
}
