import { Offer, OfferState } from '../dto';

const getDefaults = (): Offer => ({
  id: 1,
  state: OfferState.COMPLETED,
  companyId: 1,
  paymentMethodId: 1,
  serviceOffers: [],
});

export const getOfferMock = (p?: Partial<Offer>): Offer => ({
  ...getDefaults(),
  ...p,
});
