import { OrderedSectionNames } from '../enums';

export const SERVICES_SECTIONS: {
  [serviceType in OrderedSectionNames]: { label: string; color: string };
} = {
  accounting: {
    label: 'Comptable',
    color: 'm-green',
  },
  legal: {
    label: 'Juridique',
    color: 'm-purple',
  },
  social: {
    label: 'Social',
    color: 'm-orange',
  },
  sales: {
    label: 'Commercial',
    color: 'm-blue',
  },
  administrative: {
    label: 'Administratif',
    color: 'm-yellow',
  },
  training: {
    label: 'Formation',
    color: 'm-green',
  },
  fiscal: {
    label: 'Fiscal',
    color: 'm-red',
  },
  'assets-management': {
    label: 'Assurance',
    color: 'm-pink',
  },
  other: {
    label: 'Autre',
    color: 'm-default',
  },
};
