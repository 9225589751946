import { ServicesState } from '../dto';
import { SERVICE_ELIGIBILITY, SERVICE_STATE, SERVICE_TYPE } from '../enums';

const getDefaults: () => ServicesState = () => ({
  accounting: {
    type: SERVICE_TYPE.ACCOUNTING,
    typeLabel: 'Comptabilité de trésorerie',
    state: SERVICE_STATE.ACTIVATED,
    eligibility: SERVICE_ELIGIBILITY.ELIGIBLE,
    isTrialing: false,
    trialRemainingDays: 0,
    trialPeriodDays: 30,
    isFree: false,
    isValid: true,
    isActive: true,
    serviceCode: 'accounting:liberté',
    serviceCodeLabel: 'liberté',
    activatedAt: new Date('2021-12-01'),
    suspendedAt: undefined,
    shouldShowModuleLink: true,
  },
  invoicing: {
    type: SERVICE_TYPE.INVOICING,
    typeLabel: 'Facturation',
    state: SERVICE_STATE.ACTIVATED,
    eligibility: SERVICE_ELIGIBILITY.ELIGIBLE,
    shouldShowModuleLink: true,
  },
  creation: {
    type: SERVICE_TYPE.LEGAL,
    typeLabel: 'Création',
    state: SERVICE_STATE.NOT_STARTED,
    eligibility: SERVICE_ELIGIBILITY.NOT_ELIGIBLE,
    shouldShowModuleLink: true,
  },
  features: {
    hasIntercom: true,
    hasInvoicingHelp: true,
    hasNotification: true,
    hasNotificationEmailConfirmation: true,
    hasSettingsHelp: true,
  },
});

export const getCompanyServicesStateMock: (p?: Partial<ServicesState>) => ServicesState = (
  p?: Partial<ServicesState>,
): ServicesState => ({
  ...getDefaults(),
  ...p,
});
