import { InvoicingSettings } from './invoicing-settings.dto';
import { PricingSettings } from './pricing-settings.dto';
import { Service } from './service.dto';

export class ServiceOffer {
  id!: number;
  serviceType!: ServiceType;
  serviceCode?: ServiceCode;
  offerId!: number;
  serviceId?: number;
  pricingSettings!: PricingSettings;
  invoicingSettings!: InvoicingSettings;
  service!: Service;
}

export enum ServiceCode {
  ACCOUNTING_INITIAL = 'accounting:initial',
  ACCOUNTING_LIBERTE = 'accounting:liberté',
  ACCOUNTING_LIBERTE_ECE = 'accounting:liberté_ece',
  ACCOUNTING_LIBERTE_ECI = 'accounting:liberté_eci',
  ACCOUNTING_ESSENTIEL = 'accounting:essentiel',
  ACCOUNTING_COMPTASTART = 'accounting:comptastart',
  ACCOUNTING_MICRO = 'accounting:micro',
  ACCOUNTING_LMP = 'accounting:lmp',
  SOCIAL = 'social',
  ACCOUNTING_LMNP = 'accounting:lmnp',
  ACCOUNTING_CONFORT_TTC = 'accounting:confort_ttc',
  ACCOUNTING_OPEN = 'accounting:open',
  ACCOUNTING_CONFORT = 'accounting:confort',
  ACCOUNTING_SCI = 'accounting:sci',
  ACCOUNTING_SERENITE = 'accounting:sérénité',
  ACCOUNTING_OPPORTUNITE = 'accounting:opportunité',
  ACCOUNTING_OPPORTUNITE_ECE = 'accounting:opportunité_ece',
  ACCOUNTING_OPPORTUNITE_ECI = 'accounting:opportunité_eci',
  ACCOUNTING_EXCLUSIVITE = 'accounting:exclusivité',
  ACCOUNTING_EXCLUSIVITE_ECE = 'accounting:exclusivité_ece',
  ACCOUNTING_EXCLUSIVITE_ECI = 'accounting:exclusivité_eci',
  ACCOUNTING_EFFICACITE = 'accounting:efficacité',
}

export enum ServiceType {
  CREATION = 'creation',
  ACCOUNTING = 'accounting',
  INVOICING = 'invoicing',
  SOCIAL = 'social',
  PROVISIONAL = 'provisional',
  ACADEMY = 'dougs_academy',
}
