import { Service, ServiceStateEnum } from '../dto';

const getDefaults = (): Service => ({
  id: 1,
  state: ServiceStateEnum.ACTIVATED,
  trialStartedAt: new Date('2022-09-27'),
  trialPeriodDays: 30,
  companyId: 1,
  parentServiceId: 1,
});

export const getServiceMock = (p?: Partial<Service>): Service => ({
  ...getDefaults(),
  ...p,
});
