export enum OrderedSectionNames {
  ACCOUNTING = 'accounting',
  ADMINISTRATIVE = 'administrative',
  FISCAL = 'fiscal',
  LEGAL = 'legal',
  SALES = 'sales',
  SOCIAL = 'social',
  TRAINING = 'training',
  ASSETS_MANAGEMENT = 'assets-management',
  OTHER = 'other',
}
