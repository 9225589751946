export class InvoicingSettings {
  id!: number;
  companyId!: number;
  periodicity?: InvoicingPeriodicity;
  adjustmentInstallmentPaymentCount?: number;
  adjustmentDiscountPercent?: number;
  isFreeForever?: boolean;
  hasAdjustment?: boolean;
}

export enum InvoicingPeriodicity {
  ANNUALLY = 'annually',
  MONTHLY = 'monthly',
}
